import { RouteType } from "../types/shared";

import IndexPage from "../pages/Indexpage";

import LoginPage from "../pages/user/LoginNew";
import LogoutPage from "../pages/user/Logout";
import SigninPage from "../pages/user/Login";
import RegisterPage from "../pages/user/Register";
import VerifyEmailPage from "../pages/user/VerifyEmail";
import ForgotPasswordPage from "../pages/user/ForgotPassword";

import HomePage from "../pages/Homepage";
import ProfilePage from "../pages/user/Profile";
import ProfileEditPage from "../pages/user/ProfileEdit";
import ProfileCardEditPage from "../pages/user/ProfileCardEdit";
import PaymentCardPage from "../pages/user/PaymentCard";
import SettingsPage from "../pages/user/Settings";  
import BarsPage from "../pages/user/Bars";

import EditOrderPage from "../pages/order/EditOrderPage";
import PaymentPage from "../pages/order/PaymentPage";
import CreateBarPage from "../pages/bar/CreateBarPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import TermsConditionsPage from "../pages/TermsConditionsPage";
import NotificationsPage from "../pages/NotificationsPage";

import { roleConfig } from "./roleconfig";

const noPermissionRoutes: RouteType[] = [
  {
    name: "login",
    path: "/user/login",
    component: LoginPage,
    authRole: "",
    hasTopFooterMenu: false
  },
  {
    name: "logout",
    path: "/user/logout",
    component: LogoutPage,
    authRole: "",
    hasTopFooterMenu: false
  },
  {
    name: "signin",
    path: "/user/signin",
    component: SigninPage,
    authRole: "",
    hasTopFooterMenu: false
  },
  {
    name: "register",
    path: "/user/signup",
    component: RegisterPage,
    authRole: "",
    hasTopFooterMenu: false
  },
  {
    name: "verify email",
    path: "/user/verify",
    component: VerifyEmailPage,
    authRole: "",
    hasTopFooterMenu: false
  },
  {
    name: "forgot password",
    path: "/user/forgot-password",
    component: ForgotPasswordPage,
    authRole: "",
    hasTopFooterMenu: false
  }
];

const lawyerRoutes: RouteType[] = [
  {
    name: "home",
    path: "/home",
    component: HomePage,
    authRole: roleConfig.user.lawyer,
    hasTopFooterMenu: true
  },
  {
    name: "profile",
    path: "/user/profile",
    component: ProfilePage,
    authRole: roleConfig.user.lawyer,
    hasTopFooterMenu: true
  },
  {
    name: "profile",
    path: "/user/profile/edit",
    component: ProfileEditPage,
    authRole: roleConfig.user.lawyer,
    hasTopFooterMenu: true
  },
  {
    name: "paymentcard",
    path: "/user/paymentcard",
    component: PaymentCardPage,
    authRole: roleConfig.user.lawyer,
    hasTopFooterMenu: true
  },
  {
    name: "profile",
    path: "/user/profile/cardedit",
    component: ProfileCardEditPage,
    authRole: roleConfig.user.lawyer,
    hasTopFooterMenu: true
  },
  {
    name: "settings",
    path: "/user/settings",
    component: SettingsPage,
    authRole: roleConfig.user.lawyer,
    hasTopFooterMenu: true
  },
  {
    name: "bars",
    path: "/user/bars",
    component: BarsPage,
    authRole: roleConfig.user.lawyer,
    hasTopFooterMenu: true
  },
  {
    name: "bars",
    path: "/user/bars/new",
    component: CreateBarPage,
    authRole: roleConfig.user.lawyer,
    hasTopFooterMenu: true
  },  
  {
    name: "edit-order",
    path: "/order/save",
    component: EditOrderPage,
    authRole: roleConfig.user.lawyer,
    hasTopFooterMenu: true
  },
  {
    name: "pay-order",
    path: "/order/payment",
    component: PaymentPage,
    authRole: roleConfig.user.lawyer,
    hasTopFooterMenu: true
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: PrivacyPolicyPage,
    authRole: roleConfig.user.lawyer,
    hasTopFooterMenu: true
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: TermsConditionsPage,
    authRole: roleConfig.user.lawyer,
    hasTopFooterMenu: true
  },
  {
    name: "notifications",
    path: "/notifications",
    component: NotificationsPage,
    authRole: roleConfig.user.lawyer,
    hasTopFooterMenu: true
  }
];

export const allRoutes = [
  {
    name: "index",
    path: "/",
    component: IndexPage,
    authRole: "",
    hasTopFooterMenu: true
  },
  ...noPermissionRoutes, 
  ...lawyerRoutes
];