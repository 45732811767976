import * as React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { 
  Paper,
  styled,
  Chip,
  Badge,
  Tooltip,
  Button,
  Box,
} from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";

import {
  Check as CheckIcon,
  Close as CloseIcon,
} from "@mui/icons-material";


import { PaymentIcon } from "react-svg-credit-card-payment-icons";
import { green } from "@mui/material/colors";

const PaperWrapper = styled(Paper)(
  () => `
    width: 345;
    height: 200;
    padding: 18px;
    padding-top: 36px;
`
);




export default function ActionAreaCard(props:any) {
  const { isLoading } = useSelector(({ orderReducer }) => orderReducer);
  const [ isSuccess, setIsSuccess ] = useState(true);
  console.log("paymentcard", props.cardInfo);

  useEffect(() => {
    setIsSuccess(false);
  }, []);

  return (
    <PaperWrapper elevation={24} square={false} sx={{width:345, height:200, display: "flex", flexDirection: "column", justifyContent: "space-between", margin:"8px" }}> 
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>  
        <div className="flex-none">  
          <Badge badgeContent={props?.cardInfo?.verified === "yes" ? <Tooltip title="Verified" arrow><CheckIcon sx={{ fontSize: 10 }} /></Tooltip> : props?.cardInfo?.verified === "checking" ? <Tooltip title="Checking" arrow><p>...</p></Tooltip> : <Tooltip title="No Verified" arrow><CloseIcon  sx={{ fontSize: 10 }} /></Tooltip>} color={props?.cardInfo?.verified === "yes" ? "success" : props?.cardInfo?.verified === "checking" ? "secondary" : "error"}>
            <PaymentIcon type={props?.cardInfo?.cardDetail?.brand} format="logoBorder" width={62} />
          </Badge>

        </div>
        <div className="flex-row items-center">  
          <div className="text-2xl">
            ••••• {props?.cardInfo?.cardDetail?.last4}
          </div>
          <div className="text-sm">Expires:{props?.cardInfo?.cardDetail?.exp_month}/{props?.cardInfo?.cardDetail?.exp_year}</div>
        </div>  
        <div className="flex-none">  
          {
            props?.cardInfo?.verified === "no" ? 
              <Chip label="No verified" variant="outlined" disabled /> : 
              props?.cardInfo?.verified === "checking" ?
                <Chip label="On Checking" variant="outlined" disabled /> : 
                <Chip label="Verified" variant="outlined" disabled />
          }
        </div>
      </div>
      <div className="flex justify-between" >  
        <div className="flex-none"></div>
        <div className="flex-none">
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              variant="contained"
              sx={{...(isSuccess && {
                bgcolor: green[500],
                "&:hover": {
                  bgcolor: green[700],
                },
              })}}
              disabled={isLoading}
              onClick={() => props.ClickPayment(props?.cardInfo?.paymentId)} 
            >
              Pay Now...
            </Button>
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </div>
      </div>
    </PaperWrapper>
  );
}