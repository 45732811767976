import React from "react";
import { useState, useEffect } from "react";
import { Dispatch } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider, Paper } from "@mui/material";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { placeOrder } from "../../redux/actions/order.action";
import { deleteOrderDraft } from "../../redux/actions/order.action";
import { ReduxResponse } from "../../types/store";



import { 
  Grid
} from "@mui/material";
//import { toast } from "react-toastify";

import PaymentCard from "../../components/card/paymentcardarea";

export default function PaymentPage() {
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();
  //console.log(dispatch);
  const payamountValue = process.env.REACT_APP_PRICE_FOR_AN_ORDER_DOLLAR;

  const location = useLocation();

  const orderSaveData = location.state?.newOrderData;
  console.log("OrderSaveData", orderSaveData);

  const { user, paymentlist } = useSelector(({ cognitouserReducer }) => cognitouserReducer);
  const [ selectedPayment, setSelectedPayment ] = useState(null);

  useEffect(() => {
    const SelectedCard = paymentlist.filter((item:any) => item.verified === "yes" && item.paymentId === user.primarypayment);
    if(SelectedCard.length > 0)
      setSelectedPayment(SelectedCard[0]);
  }, [paymentlist]);
  
  const emptyDraft = () => {
    if(orderSaveData?.ID) {
      console.log("empty");
    } else {
    
      dispatch(deleteOrderDraft({
      }))
        .then((response: ReduxResponse) => {
          console.log(response);
          navigate("/");
        })
        .catch((error: Error) => {
          console.log(error);
          navigate("/");
        });
    }
  };
  
  const ClickPayment = (paymentId:string) => {
    dispatch(placeOrder({
      ...orderSaveData,
      paymentId
    }))
      .then((response: ReduxResponse) => {
        emptyDraft();

        if (response.success) {
          toast.success(response.message);
          
        } else {
          toast.error(response.message);
        }
      })
      .catch((error: Error) => {
        toast.error(error.message);
      });
  };

  return (user && user.primarypayment) ? (
    <main className="flex justify-center w-full">

      <div className="container flex flex-col items-center justify-center mx-4 pb-16">
        
        <Paper className="flex flex-col w-[80%] max-w-[80%] p-8 z-10 fade-up-anim anim-500">
          <div className="flex flex-col items-center">
            <h1 className="m-4 text-3xl">Please pay ${payamountValue} for your order!</h1>
          </div>
          <div className="container flex flex-col items-center justify-center py-8">
            <div className="flex-col justify-center">
              <Grid container spacing={2}>  
                {
                  selectedPayment ? 
                    <Grid item xs={12} lg={12} xl={12} className="flex justify-center" >
                      <PaymentCard cardInfo={selectedPayment} ClickPayment={ClickPayment} />
                    </Grid>
                    :
                    <div>No verified primary payment card linked!!!</div>
                }
              </Grid>
            </div>
          </div>

          <Divider flexItem />
        </Paper>
      </div>

    </main>
  ) : <></>;
}