import { Dispatch } from "redux";
import * as Actions from "../constants";
import axios, { AxiosError } from "axios";
import { handleAxiosError } from "../../utils/functions";

// Get order list action
export const getOrderList: any = () => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.GET_ORDER_LIST_REQUEST });
  return axios.get(`${process.env.REACT_APP_SERVER_API}/order`)
    .then(response => {
      if(response.data.success === true) {
        dispatch({
          type: Actions.GET_ORDER_LIST_SUCCESS,
          payload: response.data
        });
        return response.data;
      } else {
        dispatch({
          type: Actions.GET_ORDER_LIST_FAILURE
        });
      }
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.GET_ORDER_LIST_FAILURE,
        error
      });
      
      return [];
      /*
      //handleAxiosError(error);
      */
    });
};

// Get Order Detail action
export const getOrderInfo : any = (data : any) => async (dispatch : Dispatch) => {
  dispatch({ type : Actions.GET_ORDER_DETAIL_REQUEST});
  return axios.post(`${process.env.REACT_APP_SERVER_API}/order/detail`, data)
    .then(response => {
      if(response.data.success === true) {
        dispatch({
          type: Actions.GET_ORDER_DETAIL_SUCCESS,
          payload: response.data
        });
        return response.data;
      } else {
        dispatch({
          type: Actions.GET_ORDER_DETAIL_FAILURE
        });
      }
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.GET_ORDER_DETAIL_FAILURE,
        error
      });

      return handleAxiosError(error);
    });
};

// Create new order action
export const placeOrder: any = (data: any) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.CREATE_ORDER_REQUEST });

  const saveData = {
    ...data,
  };

  return axios.post(`${process.env.REACT_APP_SERVER_API}/order`, saveData)
    .then(response => {
      dispatch({ type: Actions.CREATE_ORDER_SUCCESS });
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.CREATE_ORDER_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

export const placeOrderDraft: any = (data: any) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.CREATE_DRAFT_ORDER_REQUEST });

  const saveData = {
    ...data,
  };

  return axios.post(`${process.env.REACT_APP_SERVER_API}/order/draft`, saveData)
    .then(response => {
      dispatch({ type: Actions.CREATE_DRAFT_ORDER_SUCCESS });
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.CREATE_DRAFT_ORDER_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Update order status action
export const updateOrderStatus: any = (data: any) => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.UPDATE_ORDERSTATUS_REQUEST});
  return axios.post(`${process.env.REACT_APP_SERVER_API}/order/status`, data)
    .then(response => {
      if(response.data.success === true) {
        dispatch({
          type: Actions.UPDATE_ORDERSTATUS_SUCCESS,
          payload: response.data
        });
      } else {
        dispatch({
          type: Actions.UPDATE_ORDERSTATUS_FAILURE,
          payload: response.data
        });
      }
      return response.data;
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.UPDATE_ORDERSTATUS_FAILURE,
        error
      });

      handleAxiosError(error);
    });
};

// Get Draft Data
export const getOrderDraft: any = () => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.GET_DRAFT_ORDER_REQUEST });

  return axios.get(`${process.env.REACT_APP_SERVER_API}/order/draft`)
    .then(response => {
      if(response.data.success === true) {
        dispatch({
          type: Actions.GET_DRAFT_ORDER_SUCCESS,
          payload: response.data
        });
        return response.data;
      } else {
        dispatch({
          type: Actions.GET_DRAFT_ORDER_FAILURE
        });
      }
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.GET_DRAFT_ORDER_FAILURE,
        error
      });
      
      return null;
    });
};

// Delete Draft Data
export const deleteOrderDraft: any = () => async (dispatch: Dispatch) => {
  dispatch({ type: Actions.DELETE_DRAFT_ORDER_REQUEST });

  return axios.delete(`${process.env.REACT_APP_SERVER_API}/order/draft`)
    .then(response => {
      if(response.data.success === true) {
        dispatch({
          type: Actions.DELETE_DRAFT_ORDER_SUCCESS,
        });
        return response.data;
      } else {
        dispatch({
          type: Actions.DELETE_DRAFT_ORDER_FAILURE
        });
      }
    })
    .catch((error: AxiosError) => {
      dispatch({
        type: Actions.DELETE_DRAFT_ORDER_FAILURE,
        error
      });
      
      return null;
    });
};